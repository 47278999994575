import * as React from "react";

import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

// import "@stylesPages/NotFound.scss";
import "../styles/pages/NotFound.scss";

// import Logo from "@icons/logo-big.svg";
import Logo from "../assets/images/icons/logo-big.svg";

// import { SEO } from "@components";
import { SEO } from "../components";

const NotFoundPage = () => {
  const { t } = useTranslation("notfound");

  return (
    <section className="not-found">
      <SEO title="404" />
      <img src={Logo} alt="" className="not-found__logo" />
      <h1 className="not-found__title">{t("title")}</h1>
      <p className="not-found__description">
        {t("description")}
        <Link to="/" className="not-found__link">{t("link")}</Link>
      </p>
    </section>
  );
};

export default NotFoundPage;

export const Head = () => {

  return (
    <SEO title="404" />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;